<template>
  <div class="bg-primary min-h-screen flex flex-col">
    <NavBar />
    <div
      class="
        max-w-sm
        mx-auto
        flex-1 flex flex-col
        items-center
        justify-center
        px-2
      "
    >
      <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
        <h1 class="mb-8 text-3xl text-center">Change Your Password</h1>
        
        
         <v-text-field
                outlined
                v-model="password.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :error-messages="passwordErrors"
                label="Password*"
                :type="show1 ? 'text' : 'password'"
                required
                @change="$v.password.password.$touch()"
                @blur="$v.password.password.$touch()"
              ></v-text-field>

              <v-text-field
                outlined
                v-model="password.confirm_password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
                :type="show2 ? 'text' : 'password'"
                :error-messages="confirmPasswordErrors"
                label="Confirm Password*"
                required
                @change="$v.password.confirm_password.$touch()"
                @blur="$v.password.confirm_password.$touch()"
              ></v-text-field>
         
        <small class="text-red">{{tokenError}}</small>

        <v-btn
        @click="changePassword()"
          type="submit"
          color="teal"
          dark
          :loading="buttonLoader"
          class="
          loading
            w-full
            text-center
            py-3
            rounded
            bg-yellow-fade
            text-black
            hover:bg-green-dark
            focus:outline-none
            my-1
          "
        >
          Change Password
        </v-btn>

        
      </div>

      <div class="text-grey-dark mt-6">
        No need to reset your password?
        <a class="no-underline border-b border-yellow text-yellow-fade" href="../login/">
          Login </a
        >.
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import { validationMixin } from "vuelidate";
import DataService from "@/authentication/data.service";
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   props: [ "code","id"],
  components: {
    NavBar,
  },
  setup() {},
   validations() {
    return {
      password: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(20),
        },
        confirm_password: {
          required,
          sameAs: sameAs(function () {
            return this.password.password;
          }),
        },
      },
    };
  },
  data(){
    return{
      tokenError:'',
      errors:[],
       buttonLoader:false,
       show1: false,
      show2: false,
      password: {
        confirm_password: null,
        password: null,
      },
      loading:false,       
        email:"",
    
      
  }},
   
   computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.password.$dirty) return errors;
      !this.$v.password.password.required && errors.push("Item is required");
      !this.$v.password.password.minLength &&
        errors.push("password should not be less than 8 characters");
      !this.$v.password.password.maxLength &&
        errors.push("password should not be more than 20 characters");
      return errors;
    },
    //confirm password Errors
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) return errors;
      !this.$v.password.confirm_password.required &&
        errors.push("Item is required");
      !this.$v.password.confirm_password.sameAs &&
        errors.push("Passwords dont match");
      return errors;
    },
  },
 
   methods: {
      changePassword() {
      this.$v.$touch();
      if (
        !this.$v.password.password.$invalid &&
        !this.$v.password.confirm_password.$invalid
      ) {
        this.buttonLoader = true;
        const data = {
          password: this.password.password,
          uid: this.$route.params.id,
          token: this.$route.params.code,
          password_confirm:this.password.confirm_password
        };

       DataService.passwordResetConfirmation(data).then(
        (response) => {
          
          if(response.data.message === "success"){
            this.$router.push({ path: '/auth/login' })
            this.buttonLoader = false;
          }
         this.buttonLoader = false;
       
         this.tokenError= "The token value does not match the required pattern. Request a new token."
        },
        (error) => {
          const errors =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
            this.buttonLoader = false;
             this.tokenError= "The token value does not match the required pattern. Request a new token."
             this.errors=errors
           //for (const value of Object.entries(errors)) {
           //   this.errors.push(value)
           //   console.log(value);
           // }
        }
      );
        
      }
    },

      
  }
};
</script>
